import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container templates" }
const _hoisted_2 = { class: "d-flex w-100 justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }
const _hoisted_4 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PagesModal = _resolveComponent("PagesModal")!
  const _component_DeleteBrandDialog = _resolveComponent("DeleteBrandDialog")!
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_PagesTable = _resolveComponent("PagesTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PagesModal, {
      title: _ctx.editData?.id ? 'Edit page' : 'Add page',
      loading: _ctx.isLoading,
      data: _ctx.editData?.id ? _ctx.editData : undefined,
      opened: _ctx.createPageOpened,
      "create-data": _ctx.editData,
      isHomePage: _ctx.isHomePage,
      onHide: _ctx.cleanData,
      onCancel: _ctx.cleanData,
      onSubmit: _ctx.actionWithCategory
    }, null, 8, ["title", "loading", "data", "opened", "create-data", "isHomePage", "onHide", "onCancel", "onSubmit"]),
    _createVNode(_component_DeleteBrandDialog, {
      title: _ctx.editData?.title,
      opened: _ctx.deletePageOpened,
      loading: _ctx.isLoading,
      "is-show": false,
      onHide: _ctx.cleanData,
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _ctx.cleanData
    }, null, 8, ["title", "opened", "loading", "onHide", "onConfirm", "onCancel"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageMainHeading, { title: "Pages" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppButton, {
                type: "secondary",
                size: "lg",
                onClick: _ctx.editingHomePage
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BtnContent, { label: "SEO home" })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AppButton, {
                type: "secondary",
                size: "lg",
                onClick: _ctx.editingPage
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BtnContent, {
                    icon: "plus",
                    label: "Create Page"
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.pagesList.length,
      "search-query": _ctx.searchQuery,
      "empty-button-text": "Create page",
      "empty-key": "pages",
      "empty-icon": "pages",
      onEmptyButtonClick: _ctx.editingPage
    }, {
      default: _withCtx(() => [
        (_ctx.pagesList.length)
          ? (_openBlock(), _createBlock(_component_PagesTable, {
              key: 0,
              "pages-list": _ctx.pagesList,
              "total-rows-count": _ctx.totalPagesCount,
              "view-configuration": _ctx.viewConfiguration,
              onToggleEdit: _ctx.editingPage,
              onDelete: _ctx.removingPage,
              onChangeViewConfiguration: _ctx.changeViewConfigurationHandler
            }, null, 8, ["pages-list", "total-rows-count", "view-configuration", "onToggleEdit", "onDelete", "onChangeViewConfiguration"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show-table", "search-query", "onEmptyButtonClick"])
  ], 64))
}