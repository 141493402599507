
import TabsDialog from "@/components/ui/Modal/Dialog/TabsDialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import AutosizeTextarea from "@/components/Form/AutosizeTextarea.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import DragAndDrop from "@/components/ui/DragAndDrop.vue";
import { IPages } from "@/api/interfaces/pages/IPages";
export default defineComponent({
  name: "PagesModal",

  components: {
    AutosizeTextarea,
    DragAndDrop,
    AppCheckbox,
    AppButton,
    AppInput,
    TabsDialog,
    AppInputsValidationProvider,
  },
  emits: ["submit", "hide", "cancel"],

  props: {
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: true },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    isShow: { type: Boolean, default: true },
    title: { type: String, default: "" },
    isHomePage: { type: Boolean, default: false },
    data: {
      type: Object as PropType<IPages>,
      required: false,
    },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      buttonTextUpdated: this.buttonText,
      categoryName: "",
      avatar: "",
      valid: false,
      isChecked: false,
      editData: {} as IPages,
      file: {} as File,
      selectedTab: 0
    };
  },
  computed: {
    image() {
      if (this.data?.image && !this.file?.name) {
        return this.data?.image;
      }
      return "";
    },
    disabledButton(){
      if(this.isHomePage){
        return true
      }
      return  !!(this.editData?.path?.length &&this.editData?.h1?.length )
    },
    tabsArray(){
      return this.isHomePage ? [] : ["Main", "SEO"]
    },
    tabs() {
     return this.tabsArray.map((tab, idx) => ({
        title: tab,
        id: idx,
      })) || []
    }
  },
  watch: {
    data(data: IPages): void {
      if (data) {
        this.editData = { ...data };
      } else {
        this.editData = {} as IPages;
      }
    },
    opened(){
      this.selectedTab = this.isHomePage ? 1 : 0 
    }
  },
  methods: {
    submit() {
      if (this.file?.name) {
        this.editData.image = this.file;
      }
      this.editData.active = !!this.editData.active;
      this.$emit("submit", this.editData);
      this.file = {} as File;
    },

    onChangeErrorState(error: any) {
      this.valid = !error;
    },

    hide() {
      this.$emit("hide", false);
      this.editData = {} as IPages;
      this.file = {} as File;
    },
    setImage(image: File) {
      this.file = image;
    },
  },

  inheritAttrs: false,
});
