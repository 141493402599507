
  import { ComputedRef, defineComponent, PropType, computed } from "vue";
  import {
    ITableBodyRow,
    ITableHeadCell
  } from "@/components/Table/ts/interfaces/TableStructure";
  import { sortItem, sortOrders } from "@/enums/main/sortOrders";
  import {
    ITableViewConfiguration
  } from "@/components/Table/ts/interfaces/common";
  import AppTable from "@/components/Table/ts/AppTable.vue";
  import { useBaseTable } from "@/hooks/tables/useTable";
  import { formatDate } from "@/hooks/payments/usePayments";
  import { badgeTypes } from "@/components/ui/AppBadge/interfaces";
  import { IPages } from "@/api/interfaces/pages/IPages";
  type tableHeadCells = ITableHeadCell[];
  
  export type templatesTableSort = {
    bySubject: sortItem;
  };
  
  export default defineComponent({
    name: "PagesTable",
    components: {
      AppTable
    },
  
    emits: {
      toggleEdit: null,
      delete: null,
      changeViewConfiguration: null
    },
    setup(props, { emit }) {
      const initialViewConfiguration = {
        page: 1,
        perPage: 10
      };
  
      const initialSortOptions: templatesTableSort = {
        bySubject: {
          order: sortOrders.turnOff,
          keyName: "subject",
          keyNameInModel: "subject"
        }
      };
  
      // rows actions
      const pageEditHandler = (data: IPages) => rowClickHandler(data);
      const pageDeleteHandler = (id: number | undefined) => emit("delete", id);
  
      async function rowClickHandler(data: IPages): Promise<void> {
        emit("toggleEdit",  data );
      }
  
      // table model
      const pagesList: ComputedRef<IPages[]> = computed(
        () => props.pagesList
      );
  
      const tableRefs = useBaseTable({
        initialViewConfiguration,
        initialSortOptions,
        model: pagesList,
        generateRows(pagesList: IPages[]): ITableBodyRow[] {
          return pagesList.map(
            ({
              id,
              h1,
              createdAt,
              active,
              SEO_block,
              path,
              ...res
            }: IPages): ITableBodyRow => {
              let pageStatus = active ? "active" : "inactive";
              return {
                status: pageStatus,
                onClick: (): void => {
                  pageEditHandler({
                    id,
                    h1,
                    createdAt,
                    active,
                    SEO_block,
                    path,
                  ...res
                  });
                },
                actions: [
                  {
                    text: "Edit",
                    onClick: (): void => {
                      pageEditHandler({
                        id,
                        h1,
                        active,
                        createdAt,
                        SEO_block,
                        path,
                        ...res
                      });
                    }
                  },
                  {
                    text: "Remove",
                    onClick: (): void => {
                      pageDeleteHandler(id);
                    }
                  }
                ],
                cells: [
                  {
                    label: h1
                  },
                  {
                    label: formatDate(createdAt)
                  },
                  {
                    label: SEO_block.slice(0, 25) + "..." || ""
                  },
                  {
                    label: path
                  },
                  {
                  badge: {
                    type: active
                      ? badgeTypes.lightSecondary
                      : badgeTypes.danger,

                    label: active ? "Active" : "Inactive"
                  }
                },
                ]
              };
            }
          );
        },
        onViewConfigurationChanged(
          viewConfiguration: ITableViewConfiguration
        ): void {
          // get data by parent component
          emit("changeViewConfiguration", viewConfiguration);
        }
      });
  
      const {
        rows,
        changeViewConfiguration,
        changeSorting,
        viewConfiguration,
        currentSort
      } = tableRefs;
  
      return {
        rows,
        changeViewConfiguration,
        changeSorting,
        viewConfiguration,
        currentSort
      };
    },
  
    props: {
      totalRowsCount: {
        type: Number,
        required: true
      },
  
      pagesList: {
        type: Array as PropType<IPages[]>,
        required: true
      },
  
      parentViewConfiguration: {
        type: Object as PropType<ITableViewConfiguration>,
        default: () => ({
          page: 1,
          perPage: 10,
          search: "",
          sortField: "subject",
          sortType: "ASC"
        })
      }
    },
  
    computed: {
      tableHead(): tableHeadCells {
        const { bySubject } = this.currentSort;
        return [
          {
            size: "xl",
            label: "H1",
            id: "H1"
          },
          {
            size: "lg",
            label: "data created",
            id: "data-created"
          },
          {
            size: "lg",
            label: "SEO block",
            id: "SEO_block"
          },
          {
            size: "xs",
            label: "path",
            id: "path"
          },
          {
          size: "sm",
          label: "Status",
          id: "pages-status"
          },
        ];
      }
    }
  });
  