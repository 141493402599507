import { Store } from "vuex";
export const createUser = async (data: any, store: Store<any>) => {
  const { success, message, data: userId } = await store.dispatch("addUser", {
    data: { ...data }
  });
  if (!success) {
    await store.dispatch("showErrorNotification", message);
  }
  if (data.avatar) {
    await store.dispatch("uploadUserAvatar", {
      avatar: data.avatar,
      id: userId
    });
  }
  return success;
};
