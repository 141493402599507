
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AvatarUpload from "@/components/ui/AvatarUpload.vue";
import AppInput from "@/components/Form/AppInput.vue";
import PhoneInput from "@/components/ui/Modal/Dialog/instances/PhoneInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import { defineComponent } from "@vue/runtime-core";
import { AddUserModalDto } from "@/api/interfaces/users/users.dto";

interface UserDialogData {
  buttonTextUpdated: string;
  userName: string;
  userPhone: string;
  userEmail: string;
  userPassword: string;
  repeatPassword: string;
  avatar: File | null;
  validation: boolean;
  parentTouched: boolean;
}

const initialData = () => ({
  userName: "",
  avatar: null,
  userEmail: "",
  userPhone: "",
  userPassword: "",
  repeatPassword: "",
  isPasswordTouch: false,
  validation: false,
  parentTouched: false
});

export default defineComponent({
  name: "CreateUserDialog",
  components: {
    AppButton,
    PhoneInput,
    AppInput,
    AvatarUpload,
    Dialog,
    AppInputsValidationProvider
  },

  inheritAttrs: false,

  props: {
    disabled: { type: Boolean, default: true },
    opened: { type: Boolean, default: false },
    title: { type: String, default: "Create User" },
    buttonText: { type: String, required: false },
    canCancel: { type: Boolean, default: false },
    endCreate: {
      type: Function,
      required: true
    },
    loading: { type: Boolean, default: false }
  },
  emits: {
    hide: null
  },

  data() {
    return {
      ...initialData(),
      buttonTextUpdated: this.buttonText || this.title
    } as UserDialogData;
  },
  computed: {
    isRepeat() {
      return this.userPassword === this.repeatPassword;
    }
  },

  methods: {
    async submit(): Promise<void> {
      const data: AddUserModalDto = {
        fullName: this.userName,
        email: this.userEmail,
        password: this.userPassword,
        phone: this.userPhone
      };

      if (this.avatar) {
        data.avatar = this.avatar;
      }
      const success = await this.endCreate(
        Object.fromEntries(
          Object.entries(data).filter(entry => {
            const value = entry[1];
            return typeof value === "string" ? value.length > 2 : true;
          })
        )
      );
      if (success) {
        Object.assign(this.$data, initialData());
      }
    },

    onChangeErrorState(error: boolean) {
      this.validation = error;
    },

    hide() {
      this.$emit("hide");
    },

    fileUpload(file: any) {
      this.avatar = file;
    }
  }
});
