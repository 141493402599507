import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "d-flex justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }
const _hoisted_4 = { class: "page-heading-toolbar--col" }
const _hoisted_5 = {
  key: 0,
  class: "page-heading-toolbar--col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShadowControl = _resolveComponent("ShadowControl")!
  const _component_SelectDropdown = _resolveComponent("SelectDropdown")!
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_AccountsTable = _resolveComponent("AccountsTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!
  const _component_CreateUserDialog = _resolveComponent("CreateUserDialog")!
  const _component_DeleteUserDialog = _resolveComponent("DeleteUserDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageMainHeading, { title: "Accounts" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ShadowControl, {
                id: "Accounts-search",
                class: "search-control",
                icon: "search",
                placeholder: "Search...",
                name: "accounts-search",
                "input-timeout": true,
                onChange: _ctx.searchHandler
              }, null, 8, ["onChange"])
            ]),
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_SelectDropdown, {
                    "on-select": _ctx.onFilterSelect,
                    list: _ctx.filter
                  }, null, 8, ["on-select", "list"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_Transition, { name: "fade" }, {
              default: _withCtx(() => [
                (_ctx.accountsList.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_AppButton, {
                        type: "secondary",
                        size: "lg",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createUserDialogOpened = true))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_BtnContent, {
                            icon: "plus",
                            label: "Create User"
                          })
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.accountsList.length,
      "search-query": _ctx.searchQuery,
      "empty-button-text": "Create Account",
      "empty-key": "accounts",
      "empty-icon": "accounts",
      onEmptyButtonClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.createUserDialogOpened = true))
    }, {
      default: _withCtx(() => [
        (_ctx.accountsList.length)
          ? (_openBlock(), _createBlock(_component_AccountsTable, {
              key: 0,
              "accounts-list": _ctx.accountsList,
              "total-rows-count": _ctx.totalAccountsCount,
              "initial-view-configuration": _ctx.viewConfiguration,
              sort: _ctx.sortConfig,
              class: "mb-5",
              onToggleBlock: _ctx.userToggleBlockHandler,
              onDelete: _cache[1] || (_cache[1] = id => _ctx.selectUserToDelete(_ctx.accountsList, id)),
              onChangeViewConfiguration: _ctx.changeViewConfigurationHandler
            }, null, 8, ["accounts-list", "total-rows-count", "initial-view-configuration", "sort", "onToggleBlock", "onChangeViewConfiguration"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show-table", "search-query"]),
    _createVNode(_component_CreateUserDialog, {
      opened: _ctx.createUserDialogOpened,
      loading: _ctx.isLoading,
      "end-create": _ctx.onUserCreateSubmit,
      onHide: _ctx.hideHandler
    }, null, 8, ["opened", "loading", "end-create", "onHide"]),
    _createVNode(_component_DeleteUserDialog, {
      opened: _ctx.deleteUserDialogOpened,
      "user-name": _ctx.selectedUser.name,
      "user-email": _ctx.selectedUser.email,
      "user-photo": _ctx.selectedUser.avatar,
      loading: _ctx.deleteUserLoading,
      onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.deleteUserDialogOpened = false)),
      onConfirm: _ctx.onDeleteConfirm,
      onCancel: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteUserDialogOpened = false))
    }, null, 8, ["opened", "user-name", "user-email", "user-photo", "loading", "onConfirm"])
  ], 64))
}