
import { useStore } from "vuex";
import { defineComponent, computed, watch, reactive } from "@vue/runtime-core";
import {
  IUseBaseAppLayout,
  useBaseAppLayout,
} from "@/hooks/layout/useBaseAppLayout";
import { ITableViewConfiguration } from "@/components/Table/ts/interfaces/common";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import PagesTable from "@/components/Table/ts/instances/pages/PagesTable.vue";

import AppButton from "@/components/ui/Buttons/AppButton.vue";
import BtnContent from "@/components/ui/Buttons/BtnContent.vue";
import DeleteBrandDialog from "@/components/ui/Modal/Dialog/instances/DeleteBrandDialog.vue";

import PagesModal from "@/components/ui/Modal/Dialog/instances/PagesModal.vue";
import { useUserTable } from "@/hooks/tables/modules/usersTable/usersTable";
import AppTableController from "@/components/Table/ts/AppTableController.vue";
import { usePages } from "@/hooks/pages/usePages";
import { sortOrders } from "@/enums/main/sortOrders";
import { pagesSetup } from "./interfaces/pagesInterfaces";
import { IPages } from "@/api/interfaces/pages/IPages";

export default defineComponent({
  name: "Pages",
  components: {
    DeleteBrandDialog,
    PagesModal,
    BtnContent,
    AppButton,
    PageMainHeading,
    AppTableController,
    PagesTable,
  },

  setup(): pagesSetup {
    const store = useStore();

    const viewConfiguration: ITableViewConfiguration = reactive({
      page: 1,
      perPage: 10,
    });

    const {
      requestPagesHandler,
      addEditPage,
      removePage,
      changeViewConfigurationHandler,
    } = usePages(viewConfiguration);
    // Page layout refs
    const layoutRefs: IUseBaseAppLayout = useBaseAppLayout({
      initialSearchQuery: "",
      onFiltersChange: requestPagesHandler,
      onChangeSearchQuery: () => {
        changeViewConfigurationHandler({
          page: 1,
          perPage: viewConfiguration.perPage,
        });
      },
    });

    watch(viewConfiguration, () => requestPagesHandler());

    requestPagesHandler();
    const pagesList = computed(() => store.getters.pagesList);
    const totalPagesCount = computed(() => pagesList.value.length);

    return {
      ...layoutRefs,
      ...useUserTable(),
      viewConfiguration,
      pagesList,
      totalPagesCount,
      homePageData: computed(() => store.getters.homePageData),
      requestPagesHandler,
      changeViewConfigurationHandler,
      addEditPage,
      removePage,
    };
  },

  data() {
    return {
      editText: "",
      editId: 0,
      isLoading: false,
      createPageOpened: false,
      sortConfig: {
        byName: { order: sortOrders.asc, keyName: "title" },
      },
      deletePageOpened: false,
      editData: {} as IPages | undefined,
      isHomePage: false
    };
  },

  computed: {
    emptyText(): string {
      this.requestPagesHandler?.();

      return `No pages found!`;
    },
  },

  methods: {
    async removingPage(cId: number): Promise<void> {
      this.editId = cId;
      this.editData = this.pagesList.find(
        (pageItem) => pageItem.id == cId
      );
      this.deletePageOpened = true;
    },

    cleanData(): void {
      this.editData = {} as IPages | undefined;
      this.deletePageOpened = false;
      this.createPageOpened = false;
    },

    editingHomePage( ){
      this.isHomePage = true;
      this.createPageOpened = true;
      this.editData = this.homePageData;
    },

    editingPage(data: IPages) {
      this.isHomePage = false
      this.editId = data?.id;
      this.createPageOpened = true;

      this.editData = data;
    },

    async actionWithCategory(data: IPages) {
      this.isLoading = true;
      let success = false;
      if (data.id) {
        success = await this.addEditPage(data, "patch", data.id);
      } else {
        success = await this.addEditPage(data, "post");
      }
      if (success) {
        this.createPageOpened = false;
        this.cleanData();
        this.requestPagesHandler?.();
      }
      this.isLoading = false;
    },

    async onDeleteConfirm(): Promise<void> {
      this.isLoading = true;
      const success = await this.removePage(this.editData?.id);
      if (success) {
        this.deletePageOpened = false;
        this.cleanData();
        this.requestPagesHandler?.();
      }
      this.isLoading = false;
    },
  },
});
