<template>
  <div
    class="drop-area d-flex flex-column"
    :class="{ dragged, error, rendered: !!renderedPreview }"
  >
    <div
      class="drag-area"
      @drop="dropHandler"
      @dragover="dragOverHandler"
      @dragleave="dragLeaveHandler"
      @click="dragZoneClickHandler"
    />

    <transition name="fade">
      <div v-if="renderedImage" class="drop-area-preview">
        <img :src="renderedImage" alt="" />
      </div>
    </transition>

    <div v-if="areaIcon" class="drop-area-icons" @dragover.prevent>
      <svg-icon :icon="areaIcon" />
    </div>

    <div v-else class="drop-area-icons" @dragover.prevent>
      <img
        class="upload-icon upload-icon-default"
        src="@/assets/img/uploader-default.svg"
        alt=""
      />
      <img
        class="upload-icon upload-icon-drag"
        src="@/assets/img/uploader-drop.svg"
        alt=""
      />
    </div>

    <div
      class="drop-area-text pt-5 mt-2 d-flex flex-1 justify-content-center flex-column align-items-center"
      @dragover.prevent
    >
      <div v-if="$slots.capture" class=""></div>
      <strong v-else>
        <b>Drop your file here or </b>
        <b class="color-primary cursor-pointer"><label ref="fileLabel" for="input-file">browse</label></b>
      </strong>

      <span class="secondary-text d-block text-center">Supports {{ acceptedString }}</span>

      <transition name="fade">
        <p v-if="error" class="error-message color-danger mt-3">{{ error }}</p>
      </transition>
    </div>

    <input
      id="input-file"
      type="file"
      :multiple="maxCount > 1"
      :accept="fileTypes"
      @change="filesHandler($event.target.files)"
    />
  </div>

  <div v-if="uploadStatus" class="uploading-progressbar mt-3">
    <div
      class="progressbar-fill"
      :class="{
        error: uploadSuccess === false
      }"
      :style="{
        transform: `translate(${intProgress - 100}%, 0)`,
        transition: '.3s'
      }"
    ></div>
    <strong class="d-block w-100 pe-5">
      <b class="d-flex">
        <span class="upload-status">
          {{ uploadError || uploadStatus }}
        </span>
        {{ uploadStatus === "Uploading..." ? intProgress : "" }}</b>
    </strong>

    <div class="uploading-progressbar--buttons d-flex align-items-center">
      <transition name="fade" mode="out-in">
        <svg-icon
          v-if="uploadStatus === 'Completed'"
          icon="success"
          class="color-success"
        />

        <svg-icon
          v-else-if="uploadStatus === 'Error'"
          icon="stop"
          class="color-danger"
        />

        <!--        <div v-else class="d-flex">-->
        <!--          <button>-->
        <!--            <svg-icon icon="pause" />-->
        <!--          </button>-->
        <!--          <button>-->
        <!--            <svg-icon icon="stop" class="color-danger" />-->
        <!--          </button>-->
        <!--        </div>-->
      </transition>
    </div>
  </div>
</template>

<script>
import { toExtension } from "@/utills/files/toExtention";
import { fileTypes } from "@/constants/fileTypes";
import SvgIcon from "@/components/ui/SvgIcon";
import { isImage } from "@/utills/files/isImage";
import { fileRender } from "@/utills/files/fileRender";

export default {
  name: "DragAndDrop",
  components: { SvgIcon },

  props: {
    accepted: { type: Array, default: () => ["XLS", "XLSX", "CVS"] },
    maxCount: { type: Number, default: 1 },
    showSupportedFormats: { type: Boolean, default: true },
    areaIcon: { type: String, required: false },
    placeholder: { type: String, default: "" },
    loading: { type: Boolean, default: false },
    progress: { type: Number, default: 0 },
    uploadSuccess: { type: [Boolean, null], default: null },
    uploadError: { type: String, default: "" }
  },

  emits: {
    fileChanged: null
  },

  data() {
    return {
      dragged: false,
      files: [],
      dragLeaveTimeout: null,
      renderedPreview: null,
      error: null,
      loadingInterval: null
    };
  },

  computed: {
    renderedImage() {
      return this.placeholder || this.renderedPreview;
    },

    intProgress() {
      return parseInt(this.progress.toString());
    },

    fileTypes() {
      const isTable = this.accepted.find(format => {
        return (
          format.toLocaleLowerCase().includes("xl") ||
          format.toLocaleLowerCase().includes("csv")
        );
      });

      if (isTable) {
        return fileTypes.table;
      }

      return "";
    },

    acceptedString() {
      return this.accepted.map(f => `.${f}`).join(", ");
    },
    acceptedFormats() {
      return this.accepted.map(format => format.toLocaleLowerCase());
    },

    uploadStatus() {
      return this.progress >= 100
        ? this.uploadSuccess
          ? "Completed"
          : "Error"
        : this.progress === 0
        ? null
        : "Uploading...";
    }
  },

  watch: {
    files: {
      deep: true,
      handler(files) {
        this.$emit("fileChanged", files?.[0] || null);
      }
    }

    // loading: {
    //   immediate: true,
    //   handler(loading, prevLoading) {
    //     loading ? this.startLoading() : prevLoading && this.endLoading();
    //   }
    // }
  },

  methods: {
    // startLoading(step = 0.6) {
    //   if (this.progress >= 100) return;
    //
    //   this.loadingInterval = setInterval(() => {
    //     this.progress += step;
    //
    //     if ((this.loading && this.progress >= 75) || this.loading >= 100) {
    //       this.endLoading();
    //     }
    //   }, 15);
    // },
    //
    // endLoading() {
    //   if (this.progress >= 100) {
    //     clearInterval(this.loadingInterval);
    //     this.loadingInterval = null;
    //     return;
    //   }
    //
    //   this.startLoading(0.8);
    // },

    validate(fileList) {
      if (fileList.length > this.maxCount) {
        this.errorHandler("Download only one file at a time!");
        return false;
      }

      const validFormat = Array.from(fileList).every(({ name }) => {
        return this.acceptedFormats.indexOf(toExtension(name)) !== -1;
      });

      if (!validFormat) {
        this.errorHandler("Invalid file format!");
        return false;
      }
    },
    errorHandler(msg) {
      this.error = msg;
      setTimeout(() => {
        this.error = null;
      }, 3000);
    },

    async renderHandler() {
      let fileImage = null;

      this.files.forEach(file => {
        if (fileImage) return;

        if (isImage(file)) {
          fileImage = file;
        }
      });

      if (fileImage) {
        this.renderedPreview = await fileRender(fileImage);
      }
    },

    filesHandler(fileList) {
      const isValid = this.validate(fileList);

      if (isValid === false) {
        return;
      }

      if (Array.isArray(fileList)) {
        this.files = fileList;
      } else {
        this.files = Array.from(fileList);
      }

      this.renderHandler();

      // const interval = setInterval(() => {
      //   this.progress = this.progress + 0.6;
      //
      //   if (this.progress >= 100) {
      //     clearInterval(interval);
      //   }
      // }, 15);
    },

    dragZoneClickHandler() {
      if (this.$refs.fileLabel) {
        this.$refs.fileLabel.click();
      }
    },

    dragOverHandler(evt) {
      evt.preventDefault();
      if (this.loading) return;

      this.dragged = true;
    },

    dragLeaveHandler(evt) {
      evt.preventDefault();

      clearTimeout(this.dragLeaveTimeout);
      this.dragLeaveTimeout = setTimeout(() => {
        this.dragged = false;
      }, 0);
    },

    dropHandler(evt) {
      if (this.loading) return;

      evt.preventDefault();

      const files = evt.dataTransfer.files;
      let filesList = [];

      for (let i = 0; i < files.length; i++) {
        const file = evt.dataTransfer.items[i];

        if (file.kind === "file") {
          filesList.push(file.getAsFile());
        }
      }

      this.filesHandler(filesList);
      this.dragged = false;
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.drop-area {
  width: 100%;
  height: 200px;
  border: 1px dashed #bdbdbd;
  border-radius: $border-radius;
  position: relative;
  transition: 0.25s;
  transform: translate3d(0, 0, 0);

  input {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
  }

  &-preview {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    overflow: hidden;
    img {
      width: auto;
      height: auto;
      object-fit: contain;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: inherit;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(#000, 0.15);
    }
  }

  .drag-area {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }

  .upload-icon {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translate(-50%, 0);

    &-drag {
      transform-origin: center center;
      transform: scale(0) translate(-50%, 0);
      transition: 0.2s;
    }
  }

  .error-message {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.dragged {
    background-color: rgba($color-primary-blue, 0.1);
    border-color: $color-primary-blue;

    .upload-icon {
      &-drag {
        transform: scale(1) translate(-50%, 0);
      }
    }
  }

  &.error {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    background-color: rgba($color-danger, 0.1);
    border-color: $color-danger;
  }
}

.upload-status {
  white-space: nowrap;
  max-width: 80%;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.uploading-progressbar {
  height: 60px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  padding: 0 $field-padding-x;
  position: relative;
  overflow: hidden;

  .progressbar-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-primary-blue, 0.15);
    transition: 0.1s;

    &.error {
      background-color: rgba($color-danger, 0.15);

      & + strong b .upload-status {
        color: $color-danger;
      }
    }
  }

  .uploading-progressbar--buttons {
    position: absolute;
    top: 50%;
    right: $field-padding-x;
    transform: translateY(-50%);
    button {
      padding-left: 5px;
      padding-right: 5px;
    }
    .icon {
      font-size: 20px;
      &:not(.color-danger):not(.color-success) {
        color: $grey-200;
      }
    }
  }
}
</style>
